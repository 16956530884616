.card {
  border-radius: 4px;
  background: transparent;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.previewImg {
  width: 100%;
  height: 180px;
  object-fit: cover;
  aspect-ratio: 1 / 2;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.innerCard {
  border: 1px solid #b3b3b3;
  background: transparent;
  border-radius: 4px;
  width: 100%;
  min-height: 180px;
  padding: 15px 15px 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.innerCardWithImage {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

.expand {
  // position: absolute;
  // right: 8px;
  // top: 8px;
  display: flex;
  gap: 16px;
  width : calc(100% - 18px);
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #2b2b2b57;
  margin-top: 15px;
  padding-top: 10px;
}

.username {
  color: #00000063;
  cursor: pointer;
  transition: color .2s;

  &:hover {
    color: #3b3b3b;
  }
}

.hint {
  font-size: 16px;
  color: #969696;
  font-weight: 300;
}

.icon {
  &:hover {
    color: #3b3b3b;
  }

  transition: all .2s!important;
  cursor: pointer;
  color: #00000063;
}