.landing-button {
  cursor: pointer;
  color: #3b3b3b;
  font-family: IBM Plex Sans, sans-serif;
  border: none;
  background: transparent;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 8px;
}

.slides {
  overflow: scroll;
  background: linear-gradient(to right, #fff, #ece9e6);
  width: 100%;
}

.mobile-second-text {
  display: none;
}

.landing {
  color: #3b3b3b;
  font-family: IBM Plex Sans, sans-serif;
  width: 100%;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 110px;
  text-align: center;
  font-weight: 500;
  letter-spacing: -3.51667px;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 0 20px;
}

@media only screen and (max-width: 380px) {
  .landing .name {
    font-size: 42px!important;
  }

  .landing .subtitle {
    font-size: 22px!important;
  }

  .landing .open-app-button {
    padding: 12px 16px;
    font-size: 18px;
    margin-top: 30px;

    svg {
      font-size: 16px;
    }
  }
}

.svgWrapper {
  height: 18px;
  width: 25px;
}

.svgWrapper svg {
  height: 18px;
  padding-right: 6px;
  width: auto;
}

.landing .name {
  font-size: 60px;
  color: #232323;
}

h1, h2 {
  margin: 0;
}

.landing .additional-text {
  font-size: 30px;
  color: #616161;
}

.landing .subtitle {
  font-size: 40px;
  color: #616161;
  font-weight: 300;
}

.landing .smaller {
  font-size: 0.3em;
  font-weight: 200;
  letter-spacing: -1.5;
  margin-top: 10px;
}

.scrollIcon {
  width: 15.4px;
  height: 27px;
  border: 1px solid #BFBFBF;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 39px;
 
  &:after {
    content: 'Scroll Down';
    color: #BFBFBF;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
    margin-top: 10px;
    width: 100px;
    display: block;
    position: absolute;
    bottom: 0;
    text-align: center;
    animation: scrollText 2s infinite;
  }
}

i.scroll {
  background: #BFBFBF;
  width: 2px;
  height: 5px;
  display: block;
  border-radius: 40px;
  animation: scrollWheel 2s infinite;
}

@keyframes scrollWheel {
  0% {
    opacity:1;
    transform: translateY(40%);
  }
  100% {
    opacity:.7;
    transform: translateY(130%);
  }
}

@keyframes scrollText {
  0% {
    opacity: 1;
    transform: translateY(120%);
  }
  100% {
    opacity: .0;
    transform: translateY(180%);
  }
}

.open-app-button {
  line-height: 130%;
  letter-spacing: -.5px;
  box-sizing: border-box;
  text-decoration: unset;
  font-family: IBM Plex Sans, sans-serif;
  background: #3eb5f1;
  color: #f1f1f1;
  box-shadow: 0 0px 23.84px 8.16px #3eb5f163;
  cursor: pointer;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .3s ease-out;
  display: flex;
  margin-top: 32px;
}

.open-app-button:hover {
  box-shadow: 0 3px 9.84px -0.84px #3eb5f12e;
}

.privacy {
  letter-spacing: 0;
  font-weight: 200;
  line-height: 15px;
  // position: absolute;
  // bottom: 12px;
  // left: 10px;
  color: gray;
  white-space: nowrap;

  a {
    font-size: 15px;
    color: inherit;
    text-decoration: none;
  }
}

.made-by {
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 200;
  line-height: 14px;
  white-space: nowrap;

  a {
    font-size: 20px;
    color: inherit;
    text-decoration: none;
    font-family: "Dancing Script";
  }
}

.icons {
  font-size: 15px;
  margin-top: 90px;
  display: flex;
  gap: 22px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1148px) {
  .landing {
    font-size: 90px;
  }
}

@media only screen and (max-width: 843px) {
  .landing {
    font-size: 70px;
  }

  .landing .text {
    display: flex;
    flex-direction: column;
    gap: 16px
  }
}

@media only screen and (max-width: 440px) {
  .icons {
    
  }
}

@media only screen and (max-width: 606px) {
  .landing {
    font-size: 50px;
    letter-spacing: -1.5px;

    .text {
      display: flex;
      flex-direction: column;
      gap: 16px
    }
  }

  .scrollIcon {
    left: 45px;
  }

  .mobile-second-text {
    display: block;
  }

  .mobile-second-text p {
    font-size: .6em;
  }

  .mobile-second-text p:last-child {
    font-size: .4em;
  }

  .second-text-container {
    display: none;
  }

  .landing .subtitle {
    font-size: 30px;
    font-weight: 300;
  }

  .landing .additional-text {
    margin-top: 14px;
  }

  .landing .scrollIcon {
    width: 17px;
    height: 29px;
    border: 1px solid #BFBFBF;

    &:after {
      font-size: 14px;
    }
  }

  i.scroll {
    width: 2px;
    height: 5px;
  }
}

.icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #0066ff;
  color: #fff;
  position: relative;
  z-index: 101;
}

.coin {
  position: absolute;
  top: var(--coin-from-x, 24px);
  left: var(--coin-from-y, 24px);
  z-index: 100;
  opacity: 0;
}

.coin::after {
  content: "$";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  font-size: 10px;
  color: rgb(237, 196, 107);
  background: rgb(227, 162, 23);
  border: 2px solid rgb(237, 196, 107);
  border-radius: 50%;
  opacity: 0;
}

.coin--animated,
.coin--animated::after {
  animation-delay: var(--coin-delay, 0s);
  animation-duration: var(--coin-duration, 1.5s);
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

.coin--animated {
  animation-name: coin-x-axis;
  animation-timing-function: ease-in;
}

.coin--animated::after {
  animation-name: coin-y-axis-and-flip;
  animation-timing-function: ease-out;
}

@keyframes coin-x-axis {
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  to {
    left: calc(var(--coin-to-x) * 1.5);
  }
}

@keyframes coin-y-axis-and-flip {
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  to {
    transform: translateY(calc(var(--coin-to-y) * 1.5)) rotate3d(1, 1, 1, 1080deg);
  }
}

@supports (backdrop-filter: blur()) {
  .search-modal {
    background: rgb(244 243 253 / 23%)!important;
    backdrop-filter: blur(6px);
  }
}

/* .landing:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./bg.jpg');
  background-size: cover;
  background-position: center;
  filter: blur(10px);
  z-index: -1;
} */