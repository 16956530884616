import React, { useEffect, useState, useCallback } from 'react'
import { Provider } from 'react-redux'
import projectXUI from 'project-x-ui'
import { useLocalStorage } from '@rehooks/local-storage'
import { Toaster } from 'react-hot-toast'
import decodeJwt from 'jwt-decode'
import useCookie from 'react-use-cookie'
import { Routes, Route, useSearchParams, useNavigate, Navigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

import AuthorizedScreen from './components/AuthenticatedScreen'
import { QuestionSearch } from './components/AuthenticatedScreen/components/QuestionSearch'
import { UserSearch } from './components/AuthenticatedScreen/components/UsersSearch'
import { UserHistory } from './components/AuthenticatedScreen/components/UserHistory'
import { UsersByAnswer } from './components/AuthenticatedScreen/components/UsersByAnswer'
import { QuestionDetails } from './components/AuthenticatedScreen/components/QuestionDetails'

import { useMixpanel, MixpanelProvider } from '../../hooks/mixpanel'

import { useSrpcApi } from '../../hooks/useSrpcApi'

import store from '../../store'
import { handleTwitterLogin } from './components/AuthenticatedScreen/components/ActionsPanel/ActionsPanel'

const {
  shallow: { Body, Landing }
} = projectXUI

const TRACKING_ID = 'G-4LRDGG17KY'

ReactGA.initialize(TRACKING_ID)

const Test = ({ jwtMock, initialQuestion }) => {
  const [jwtClient, setLSJWT] = useLocalStorage('jwt')
  const [, setRealJWT] = useLocalStorage('jwt_real')
  const [hideSwipeHintClient, setHideSwipeHint] = useLocalStorage('hideSwipeHint')
  const [, setCookieJWT] = useCookie('jwt', '0')
  const [searchParams, setSearchParams] = useSearchParams()
  const { mixpanel } = useMixpanel()

  const searchParamsObject = Object.fromEntries(searchParams)

  useEffect(() => {
    if (searchParamsObject.jwt) {
      setLSJWT(searchParamsObject.jwt)
      setCookieJWT(searchParamsObject.jwt)
      setRealJWT(searchParamsObject.jwt)
      setSearchParams({})
    }
  }, [searchParamsObject.jwt])

  useEffect(() => {
    if (searchParamsObject.gclid && mixpanel.current) {
      mixpanel.current.people.set({
        gclid: searchParamsObject.gclid
      })
    }
  }, [searchParamsObject.gclid, Boolean(mixpanel.current)])

  const [address, setAddress] = useState()
  const [payout, setPayout] = useState(0)

  const srpcApi = useSrpcApi()

  const jwt = process.env.SERVER ? jwtMock : jwtClient
  const hideSwipeHint = process.env.SERVER ? jwtMock : hideSwipeHintClient

  let user = null
  if (jwt) {
    user = decodeJwt(jwt)
  }
  useEffect(() => {
    if (user && mixpanel.current) {
      mixpanel.current.identify(user._id)
      mixpanel.current.people.set({
        $name: user.name,
        $email: user.email
      })
    }
  }, [user, Boolean(mixpanel.current)])

  const showSwipeHint = (user && user.new) && !hideSwipeHint

  const navigate = useNavigate()

  const connectToWallet = useCallback(() => {
    if (!user) {
      return
    }

    Promise.all([import('@wagmi/core'), import('@wagmi/connectors'), import('../../config')])
      .then(async ([wagmiCore, wagmiConnectors, { config: configPromise }]) => {
        const config = await configPromise
        await wagmiCore.connect(config, {
          connector: wagmiConnectors.walletConnect({
            projectId: 'fe7e5bcbefc1e04f395a0774add234d1'
          })
        })
        console.log('wallet connected')

        if (!user.address) {
          const account = await wagmiCore.getAccount(config)
          const result = await srpcApi.saveUserCryptoAddress({ jwt, address: account.address })
          mixpanel.current.track('Wallet Connected', {
            address: account.address
          })
          setLSJWT(result.jwt)
          setCookieJWT(result.jwt)
          setRealJWT(result.jwt)
        }
      })
  }, [user])

  const hide = async () => {
    const result = await srpcApi.hideWalletModal({ jwt })
    setLSJWT(result.jwt)
    setCookieJWT(result.jwt)
    setRealJWT(result.jwt)
  }

  const createUser = async ({ email, name, answer, picture, parentMessageId, shortId, sendEmails }) => {
    const { jwt, error } = await srpcApi.createUserNew({ email, name, picture, answer, shortId, parentMessageId, sendEmails })
    if (!error) {
      if (answer) {
        mixpanel.current.track('Sign Up Success', {
          type: 'question-answer',
          questionId: shortId
        })
        navigate(`/questions/${shortId}/?jwt=${jwt}`)
      } else {
        navigate(`/?jwt=${jwt}`)
      }
    }
  }

  const updateJwt = async () => {
    const result = await srpcApi.getUserToken({ jwt })
    setLSJWT(result.jwt)
    setCookieJWT(result.jwt)
    setRealJWT(result.jwt)
  }

  console.log('userId: ', user?._id, jwt)

  const addImage = (messageId, img) => {
    srpcApi.addImage({ jwt, img, messageId })
  }

  return (
    <Provider store={store}>
      <Body
        address={address}
        userId={user?._id}
        payout={payout}
        includeSwipes
        showSwipeHint={showSwipeHint}
        hideSwipeHint={() => (!hideSwipeHint && (user && user.new)) && setHideSwipeHint(true)}
        connectToWallet={connectToWallet}
        connected={user?.address}
        isWalletModalOpenInitial={false}
        hide={hide}
        createUser={createUser}
        handleTwitterLogin={handleTwitterLogin}
        updateJwt={user?.verifiedKYC ? null : updateJwt}
        addImage={addImage}
      >
        <Toaster position='top-right' containerClassName='test-toast' /><Routes>
          <Route index element={jwt ? <AuthorizedScreen setPayout={setPayout} setAddress={setAddress} jwt={jwt} /> : <Landing jwt={jwt} />} />
          {!jwt && <Route path="/app" index element={<AuthorizedScreen jwt={jwt} />} />}
          {jwt && <Route path="/about" index element={<Landing jwt={jwt} />} />}

          <Route path="/questions" element={<QuestionSearch jwt={jwt} />} />
          <Route path="/questions/:id" element={<QuestionDetails initialQuestion={initialQuestion} jwt={jwt} />} />
          <Route path="/questions/:questionId/:answer/users" element={<UsersByAnswer jwt={jwt} />} />

          <Route path="/users" element={<UserSearch jwt={jwt} />} />
          <Route path="/users/:name" element={<UserHistory jwt={jwt} />} />
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </Body>
    </Provider>
  )
}

export default () => (
  <MixpanelProvider>
    <Test />
  </MixpanelProvider>
)
