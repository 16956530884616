.back {
  color: #3b3b3b;
  cursor: pointer;
  padding: 3px;
  position: relative;
}

.screenWithGroupContent {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans';
  max-width: 500px;
  width: calc(100% - 20px);
  gap: 10px;
  overflow: scroll;
  height: calc(100dvh - 20px);
  box-sizing: border-box;
  margin-top: 10px;
}

.usersContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // height: calc(100% - 20px)!important;
}

.screenWithGroupContent > p {
  padding: 30px 15px;
  color: #bebebe;
}

@media only screen and (max-width: 945px) {
  .screenWithGroupContent {
    display: none;
  }
}

.userItem {
  display: flex;
  // align-items: : space-between;
  gap: 12px;
  padding: 15px;
  color: #3b3b3b;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  font-family: 'IBM Plex Sans';
  cursor: pointer;
  flex-direction: column;
  gap: 24px;

  img, .imgFallback {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: 3px solid #d9d7d5;
  }

  svg {
    color: #3b3b3b;
  }

  a {
    border-radius: 4px;
    border: 1px solid #b3b3b3;
    height: 38px;
    width: 38px;
    cursor: pointer;
    padding: 6px;
    transition: box-shadow .2s;
    box-sizing: border-box;

    &:hover {
      box-shadow: 0 3px 9.84px -0.84px #3eb5f12e;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.row {
  display: flex;
  gap: 8px;
}

.close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;

  h2 {
    margin: 0;
    margin-bottom: 20px;
  }

  svg {
    cursor: pointer;
  }
}

.checkboxes__row {
  display: flex;
}

.checkboxes__item {
  padding: 15px;
}

.checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}
.checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}
.checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: #eee;
}
.checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}
.checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}
.checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #333;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
.checkbox.style-c .checkbox__body {
  color: #333;
  line-height: 1.4;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox.style-c input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}
.checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}
.checkbox.style-c:hover input ~ .checkbox__checkmark {
  background-color: #eee;
}
.checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #f7cb15;
}
.checkbox.style-c .checkbox__checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  transition: background-color 0.25s ease;
  border-radius: 4px;
}
.checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #333;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}
.checkbox.style-c .checkbox__body {
  color: #333;
  line-height: 1.4;
  font-size: 16px;
  display: flex;
  align-items: center;
}
