.body {
  display: flex;
  justify-content: center;
  width: 100vw;
  // background: #000 linear-gradient(90deg, #accbee63, #49333e);
  background: #ECE9E6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  max-height: 100dvh;
  min-height: 100dvh;
}

.close {
  width: 100%;
  display: flex;
  justify-content: space-between;

  h2 {
    margin: 0;
    margin-bottom: 14px;
  }

  svg {
    cursor: pointer;
  }
}

.address {
  overflow: hidden;
  max-width: 390px;
  display: block;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  gap: 8px;

  b {
    white-space: nowrap;
  }
}

.amount {
  text-overflow: ellipsis;
  max-width: 180px;
  display: block;
  overflow: hidden;
}

.hide {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.connectButton {
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
  background: #2b2b2b0f;
  padding: 9px 16px;
  border-radius: 4px;
  cursor: pointer;
  color: #3b3b3b;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  background: transparent;
  border: 1px solid #59acd6;
  transition: color .1s;
  margin-top: 8px;
}

.twitterSignIn {
  display: flex;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dadce0;
  color: #3c4043;
  cursor: pointer;
  font-family: "Google Sans", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  padding: 0 12px;
  align-items: center;
  transition: all .2s;
  border-radius: 4px;

  &:hover {
    border-color: #d2e3fc;
    background: rgba(66, 133, 244, .04);
  }

  svg {
    height: 18px;
    width: 18px;
  }

  & > div {
    font-family: "Google Sans", arial, sans-serif;
  }
}

.search {
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
  border: none;
  background: #2b2b2b0f;
  padding: 9px 16px;
  border-radius: 4px;
  cursor: pointer;
  color: #3b3b3b;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #59acd6;
  transition: color .1s;
  width: 20%;

  svg {
    color: #3b3b3b;
    transition: color .1s;
    margin-left: 8px;
  }
}

.input {
  box-sizing: border-box;
  border: none;
  outline: none;
  caret-color: #3b3b3b;
  color: #3b3b3b;
  font-size: 16px;
  border-radius: 4px;
  font-family: 'IBM Plex Sans', sans-serif;

  background: #2b2b2b0f;
  padding: 10px 0 10px 15px;

  width: 80%;
}
