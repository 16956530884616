import React, { useEffect, useState, useContext } from 'react'
import projectXUI from 'project-x-ui'
import { useDispatch, useSelector } from 'react-redux'
import decodeJwt from 'jwt-decode'
import { useParams, useNavigate } from 'react-router-dom'
import FlipMove from 'react-flip-move'
import { Helmet } from 'react-helmet'

import { SET_USER_QUESTIONS_AND_ANSWERS } from '../../../../../../actions/setUserQuestionsAndAnswers'
import { USERS_BY_ANSWER } from '../../../../../../actions/usersByAnswer'
import { SET_QUESTIONS_SEARCH_RESULT } from '../../../../../../actions/setQuestionSearchResult'
import { ADD_USERS_BY_ANSWER } from '../../../../../../actions/addUsersByAnswer'

import { useSrpcApi } from '../../../../../../hooks/useSrpcApi'

const {
  leafs: { UserHistoryTabs, UserQuestionsHistory, UserAnswerDifferences },
  context: { MainScreenSwipeContext }
} = projectXUI

export const UserHistory = ({ jwt }) => {
  const [questions, setQuestions] = useState({ value: null, total: 0, offset: 0 })
  const [answers, setAnswers] = useState({ value: null, total: 0, offset: 0 })
  const [selectedTab, setSelectedTab] = useState('questions')
  const [showDifference, setShowDifference] = useState(false)
  const [difference, setDifference] = useState()
  const [similarity, setSimilarity] = useState()
  const [thisUser, setThisUser] = useState()

  const usersByAnswer = useSelector(state => state.usersByAnswer)

  const srpcApi = useSrpcApi()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { screenName } = useContext(MainScreenSwipeContext)

  const { name: userId } = useParams()

  useEffect(() => {
    addUserQuestions({ reset: true })
    addUserAnswers({ reset: true })
    srpcApi.getUser({ userId })
      .then(({ user }) => setThisUser(user))
    if (jwt) {
      srpcApi.getDifference({ jwt, userId })
        .then(({ answers, similarity }) => {
          setSimilarity(similarity)
          setDifference(answers)
        })
    }
  }, [userId])

  const onUserClick = async (user) => {
    const userId = typeof user === 'string' ? user : user._id

    navigate(`/users/${userId}`)
  }

  const getUsersByAnswer = async (questionId, answer) => {
    dispatch({
      type: USERS_BY_ANSWER,
      payload: { show: true, results: [], offset: 0 }
    })
    dispatch({
      type: SET_QUESTIONS_SEARCH_RESULT,
      payload: { results: null, search: null, total: 0, offset: 0 }
    })
    dispatch({
      type: SET_USER_QUESTIONS_AND_ANSWERS,
      payload: { user: null }
    })

    const { users, total, hasMore } = await srpcApi.getUsersByAnswer({ questionId, answer, offset: usersByAnswer.offset })

    dispatch({
      type: ADD_USERS_BY_ANSWER,
      payload: { results: users, total, hasMore }
    })

    navigate(`/questions/${questionId}/${answer}/users`)
  }

  const addUserQuestions = async ({ reset } = {}) => {
    const { questions: newQuestions, hasMore, total } = await srpcApi.getUserQuestions({
      jwt,
      userId,
      offset: reset ? 0 : questions.offset
    })

    setQuestions({
      value: reset ? newQuestions.filter(i => !i.hide) : [...questions.value, ...newQuestions.filter(i => !i.hide)],
      total,
      hasMore,
      offset: reset ? 10 : questions.offset + 10
    })
  }

  const addUserAnswers = async ({ reset } = {}) => {
    const { answers: newAnswers, hasMore, total } = await srpcApi.getUserAnswers({
      jwt,
      userId,
      offset: reset ? 0 : answers.offset
    })

    setAnswers({
      value: reset ? newAnswers : [...answers.value, ...newAnswers],
      total,
      hasMore,
      offset: reset ? 10 : answers.offset + 10
    })
  }

  const respondToDifference = (messageId, answer) => {
    if (!jwt) {
      console.log('cannot respond without being authorized')
      return null
    }
    srpcApi.saveMessage({ jwt, content: answer, parentMessageId: messageId })

    const index = difference.notAnswered.findIndex(i => i._id === messageId)
    const newQuestionBase = difference.notAnswered.find(i => i._id === messageId)
    const newQuestion = {
      ...newQuestionBase,
      me: { answer, pictureUrl: user.pictureUrl },
      answersCount: {
        ...newQuestionBase.answersCount,
        [answer]: newQuestionBase.answersCount[answer] + 1
      }
    }

    const newNotAnswered = [...difference.notAnswered.slice(0, index), newQuestion, ...difference.notAnswered.slice(index + 1)]
    setDifference({ ...difference, notAnswered: newNotAnswered })
  }

  const respond = answerX => (messageId, answer) => {
    if (!jwt) {
      console.log('cannot respond without being authorized')
      return null
    }
    srpcApi.saveMessage({ jwt, content: answer, parentMessageId: messageId })

    if (answerX) {
      const index = answers.value.findIndex(i => i._id === messageId)
      const newQuestionBase = answers.value.find(i => i._id === messageId)
      const newQuestion = {
        ...newQuestionBase,
        me: { answer, pictureUrl: user.pictureUrl },
        answersCount: {
          ...newQuestionBase.answersCount,
          [answer]: newQuestionBase.answersCount[answer] + 1
        }
      }

      const newValue = [...answers.value.slice(0, index), newQuestion, ...answers.value.slice(index + 1)]
      setAnswers({ ...answers, value: newValue })
    } else {
      const index = questions.value.findIndex(i => i._id === messageId)
      const newQuestionBase = questions.value.find(i => i._id === messageId)
      const newQuestion = {
        ...newQuestionBase,
        me: { answer, pictureUrl: user.pictureUrl },
        answersCount: {
          ...newQuestionBase.answersCount,
          [answer]: newQuestionBase.answersCount[answer] + 1
        }
      }

      setQuestions({ ...questions, value: [...questions.value.slice(0, index), newQuestion, ...questions.value.slice(index + 1)] })
    }
  }

  const back = async () => {
    dispatch({
      type: SET_USER_QUESTIONS_AND_ANSWERS,
      payload: { user: null }
    })
    dispatch({
      type: USERS_BY_ANSWER,
      payload: { show: false, results: [], offset: 0 }
    })

    navigate('/')
  }

  const onQuestionClick = id => {
    navigate(`/questions/${id}`)
  }

  let user
  if (jwt) {
    user = decodeJwt(jwt)
  }

  const myHistory = userId === user?._id

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>poll.cc | {thisUser?.fullName || ''}</title>
        <meta name="description" content={`${thisUser?.fullName || ''} questions and answers history`}></meta>
      </Helmet>
      <UserHistoryTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        back={back}
        setShowDifference={setShowDifference}
        showDifference={showDifference}
        similarity={(myHistory || !jwt) ? null : similarity}
        user={thisUser}
      >
        <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}>
          {
            selectedTab === 'questions'
              ? (
                  <UserQuestionsHistory
                    style={screenName && { margin: '10px', maxWidth: 'none' }}
                    fetchQuestions={addUserQuestions}
                    hasMore={questions.hasMore}
                    respond={jwt ? respond(false) : null}
                    createNewGroup={getUsersByAnswer}
                    questions={questions.value && questions.value.map(q => ({ ...q, yourOwnQuestion: user ? user.username === q.username : false }))}
                    onUserClick={onUserClick}
                    onQuestionClick={onQuestionClick}
                  />
                )
              : showDifference
                ? (
                    <UserAnswerDifferences
                      onQuestionClick={onQuestionClick}
                      respond={respondToDifference}
                      createNewGroup={getUsersByAnswer}
                      onUserClick={onUserClick}
                      answers={difference}
                    />
                  )
                : (
                    <UserQuestionsHistory
                      style={screenName && { margin: '10px', maxWidth: 'none' }}
                      fetchQuestions={addUserAnswers}
                      hasMore={answers.hasMore}
                      respond={jwt ? respond(true) : null}
                      createNewGroup={getUsersByAnswer}
                      questions={answers.value && answers.value.map(q => ({ ...q, yourOwnQuestion: user ? user.username === q.username : false }))}
                      onUserClick={onUserClick}
                      onQuestionClick={onQuestionClick}
                    />
                  )
          }
        </FlipMove>
      </UserHistoryTabs>
    </>
  )
}
